import React from "react"
import styles from "./use-case-cs.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import checkStyles from "../../styles/commons/circlechecks.module.scss"

const UseCaseChallengesSolutions = ({ props }) => {
  const { challenges, solutions } = props

  return (
    <section
      className={[cblockStyles.cblock, cblockStyles.cblock__bubbles__bl].join(
        " "
      )}>
      <div className="container">
        <div className={cblockStyles.cblock__header}>
          <h2>Challenges & Solutions</h2>
        </div>
        <div className="row d-flex flex-row justify-content-between">
          <div className={`col-12 col-md-6 ${styles.cs__wrapper}`}>
            <div className={styles.cs__body}>
              <h3 className={styles.cs__title}>Challenges</h3>
              <ul
                className={[
                  checkStyles.circlechecks,
                  checkStyles.circlechecks__neg,
                ].join(" ")}>
                {challenges.map(challenge => (
                  <li
                    key={challenge}
                    className={checkStyles.circlechecks__item}>
                    <div className={styles.cs__itemBody}>{challenge}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={`col-12 col-md-6 ${styles.cs__wrapper}`}>
            <div className={styles.cs__body}>
              <h3 className={styles.cs__title}>Solutions</h3>
              <ul className={checkStyles.circlechecks}>
                {solutions.map(solution => (
                  <li key={solution} className={checkStyles.circlechecks__item}>
                    <div className={styles.cs__itemBody}>{solution}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UseCaseChallengesSolutions
