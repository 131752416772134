import React, { useState } from "react"
import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import FsLightbox from "fslightbox-react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import SEO from "../../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../../contexts/app.context"
import UseCaseChallengesSolutions from "../../../components/use-case-cs/use-case-cs"
import UseCaseOverview from "../../../components/use-case-overview/use-case-overview"
import UseCaseBenefits from "../../../components/use-case-benefit/use-case-benefits"
import DdosMHeroAnim from "../../../components/animated-graphs/ddosm-hero/ddosm-hero-anim"
import { Helper } from "../../../utils/helper"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import PlaySvg from "../../../components/svg/play"

const DDoSNetworkServicesPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__ddos-mitigation" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const overviewLink = "https://player.vimeo.com/video/409052332?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/network/ddos-mitigation"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>DDoS Mitigation</h1>
                <p className={styles.header__description}>
                  Volterra delivers DDoS and advanced security services to
                  protect against L3-L7+ attacks to enterprises and hosting/
                  service providers. Customers can use BGP-based redirection or
                  subscribe to our high-performance IP transit and a private
                  network across our global backbone.
                </p>
                <div className={styles.header__cta}>
                  <a
                    aria-label="Start for Free"
                    href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                    className={"btn btn-primary " + styles.header__btn}>
                    Start for Free
                  </a>
                  <button
                    onClick={openSelectedVideo}
                    className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                    <PlaySvg />
                    Watch Overview
                  </button>
                </div>
                {/* <div className={`pt-4 ${styles.header__doc}`}>
                  <p>
                    <a 
                      aria-label="View demo documentation" 
                      className={styles.header__link} 
                      href="/">
                      View demo documentation
                      <ArrowSvg />
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            {/* <button className={styles.header__imgbtn} onClick={openSelectedVideo}>
              <PlaySvg />
            </button> */}
            <DdosMHeroAnim />
          </div>
        </div>
      </section>

      <UseCaseChallengesSolutions
        props={{
          challenges: [
            "Distributed and volumetric attacks cannot be handled by on-premises security appliances",
            "Low and slow-ramping application-layer attacks cannot be mitigated by legacy DDoS appliances",
            "Managing multiple on-premises devices and transit service providers is operationally complex and expensive",
            "Long lead times and contractual issues for on-demand expansion of capacity",
          ],
          solutions: [
            "Volterra PoPs and global private backbone can mitigate massive security attacks at the source",
            "The advanced AI/ML capabilities of VoltMesh can handle sophisticated attacks from network- to API-level",
            "Using Volterra’s global network as the perimeter provides SaaS-based operations and a single-pane of glass across services",
            "Volterra’s network provides on-demand, self-service provisioning and scaling of network and security capacity",
          ],
        }}
      />

      <UseCaseOverview
        props={{
          content: {
            description: `Volterra delivers advanced security services to protect against L3-L7+ and DDoS attacks for enterprises and 
              hosting/service providers. Our SaaS-based service delivers a single-pane of glass across connectivity, networking 
              and security services to simplify operations.
              <br/>
              <br/>
              Customers can use BGP-based redirection of traffic to Volterra’s global network to augment their existing
              investments in on-premises DDoS and security appliances or they can subscribe to our IP transit alongside a L3 
              VPN network across our high-performance global backbone. `,
            diagram: {
              alt: "DDoS & Network services diagram",
              name: "ddos-network-services-diagram.png",
              width: 801,
              height: 525,
              md: {
                name: "ddos-network-services-diagram-md.png",
                width: 755,
                height: 556,
              },
              sm: {
                name: "ddos-network-services-diagram-sm.png",
                width: 480,
                height: 729,
              },
            },
            capabilities: {
              left: [
                "Volumetric DDoS defense",
                "Support for all L3-L4 services",
                "Application and API security",
                "Network firewall",
              ],
              right: [
                "Centralized observability",
                "High-performance IP transit",
                "BGP-based traffic redirection",
                "GRE tunnel or Volterra direct connect",
              ],
            },
          },
        }}
      />

      <UseCaseBenefits
        props={{
          content: {
            benefits: [
              {
                iconName: "growth-icon.svg",
                title: "Total Cost of Ownership (TCO)",
                description: `Lower your capex and opex by moving to cloud-based network perimeter security and reduce 
                reliance on appliances and a legacy hub-and-spoke architecture`,
              },
              {
                iconName: "speed-icon.svg",
                title: "Service Uptime and Profitability",
                description: `Maximize the uptime of your revenue-driving infrastructure and applications by 
                leveraging our deep expertise in mitigating sophisticated and volumetric attacks`,
              },
              {
                iconName: "deliver-icon.svg",
                title: "Modernization",
                description: `Move to a SaaS-based model with the ability to
                dynamically expand capacity and deploy new services on-
                demand without adding appliances or network capacity in
                your data-centers`,
              },
              {
                iconName: "cost-icon.svg",
                title: "Productivity",
                description: `Empower your network and DevOps via a SaaS-
                based security platform and central pane of glass to
                deliver more projects, new applications, and expand 
                capacity with existing resources`,
              },
            ],
          },
        }}
      />

      <StartDemo />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
        key="1"
      />
    </Layout>
  )
}

export default withAppContext(DDoSNetworkServicesPage)
